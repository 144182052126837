import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue') 
  },
  {
    path: '/snoop-dogg',
    name: 'snoop-dogg',
    component: () => import('../views/snoop-dogg.vue') 
  },
  {
    path: '/7bit-casino',
    name: '7bit-casino',
    component: () => import('../views/7bit-casino.vue') 
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
